<template>
    <div class="mt-2">
        <el-row :gutter="10" class="m-tb-3" v-if="field.input_type != 'CHECKBOX_GROUP' || (field.group_key && field.input_type != 'CHECKBOX') && !(field.input_type == 'WEEKDAYS' && field.allow_multiple)">
            <el-col>
                Field Required!
            </el-col>
            <el-col>
                <div class="form-group">
                    <el-radio v-model="field.validations.required" :label="true">Yes</el-radio>
                    <el-radio v-model="field.validations.required" :label="false">No</el-radio>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: "templates-formComponents-IsFieldRequired",
  props: ["field"],
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
